<script setup lang="ts">
import type { PropType } from 'vue';
import type { Singleton } from '~/types/singleton';

const props = defineProps({
  nfts: Object as PropType<Array<Singleton>>,
  pending: Boolean as PropType<boolean>,
  gridStyle: String as PropType<'grid-compact' | 'grid' | 'table'>,
});
const { nfts, pending, gridStyle } = toRefs(props);
</script>

<template>
  <div
    v-if="gridStyle !== 'table'"
    :class="[
      gridStyle === 'grid-compact'
        ? 'grid-cols-2 gap-x-6 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'
        : 'grid-cols-1 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 2xl:grid-cols-4 ',
    ]"
    class="grid w-full gap-y-4 sm:gap-y-6 lg:gap-6 2xl:gap-8"
  >
    <NftCard
      v-for="nft in nfts"
      :key="`${nft.id}${nft.auction?.id}`"
      :nft="nft"
      :compact="gridStyle === 'grid-compact'"
    />
    <template v-if="pending">
      <NftCard v-for="index in 12" :key="index" :nft="undefined" :compact="gridStyle === 'grid-compact'" />
    </template>
  </div>
</template>
